<template>
  <div>Redirecting...</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import notifications from '@/util/notifications'

export default {
  name: 'NotificationRedirect',
  mixins: [notifications],
  computed: {
    ...mapGetters([
      'project',
      'currentUserRights',
      'projectNotifications'
    ]),
  },
  async mounted() {
    const id = this.$route.params.id
    const result = await this.$store.dispatch("findNotifications", {data:{
      and: {id: id},
      with: {
        'user': {
          'one': 'users',
          'this': 'user_id'
        },
        'listener': {
          'one': 'users',
          'this': 'listener_id'
        },
        'position': {
          'one': 'position',
          'this': 'position_id'
        },
        'project': {
          'one': 'project',
          'this': 'project_id'
        },
        'additionals': {
          'one': 'material_additional',
          'this': 'item_id',
          'that': 'id',
          query: {
            and: {
              state_komm: 'BEMERKUNG'
            }
          }
        },
        'comment': {
          'one': 'comment',
          'this': 'item_id'
        }
      }
    }})
    const n = result[0];
    const event = this.createEvent(n, n.project_id, true)
    this.$router.push(event.route)
  }
}
</script>
